<template>
  <div class="yellow_bg">
    <div v-if="$lang === 'hr'" class="container pt-5 pb-5">
      <div>
        <h3 class="text-center">IZJAVA O ODRICANJU OD ODGOVORNOSTI</h3>
        <p>
          Sama Usluga ne predstavlja i ne smatra se pružanjem pravnih savjeta. Usluga pruža objedinjene informacije
          prikupljene iz raznih javno dostupnih izvora, obrađene i organizirane uz pomoć naših zaštićenih algoritama,
          softvera zaštićenog autorskim pravom i internog znanja.
        </p>
        <p>
          TA nije, i ne predstavlja se kao, ovlašteni odvjetnik. Bilo kakve pravne procjene i savjete sadržani u
          dostavljenim materijalima izradili su ovlašteni odvjetnici u RH i izričito su označeni kao takvi.
        </p>
        <p>
          Odnos između TA i Registriranog korisnika nije odnos između odvjetnika i stranke. TA štiti povjerljivost
          podataka u skladu s Politikom privatnosti opisanom na TA internetskoj stranici, ali informacije koje
          Registrirani korisnik dostavi TA nisu zaštićene sukladno propisima o odvjetničkoj tajni i TA može biti
          primorana da iste otkrije u određenim pravnim postupcima. Sukladno tome, Registrirani korisnik ne bi trebao
          dostavljati bilo koju informaciju o svojim pravnim zahtjevima za koju želi da bude zaštićena kao odvjetnička
          tajna.
        </p>
        <p>
          TA ne reklamira niti preporučuje konkretne odvjetnike, niti izravno nudi ili potiče Registrirane korisnike da
          angažiraju konkretne odvjetnike. Bilo koji Registrirani korisnik koji želi angažirati odvjetnika u RH to čini
          na svoju inicijativu i rizik, a na temelju vlastite ocjene i prosudbe.
        </p>
        <p>
          TA ne jamči ishod bilo kojeg pravnog postupka, ne potvrđuje i ne jamči vjerodostojnost bilo kojeg dokumenta
          koji je predao Registrirani korisnik i ne potvrđuje istovjetnost bilo koje osobe navedene u takvom dokumentu.
        </p>
        <div class="mb-5">
          <div>

            TA ne obećava bilo kojem Registriranom korisniku da će njegovo pravo ili zahtjev biti utvrđeni. Ishod
            procjene, preporuke i postupaka ovisi o nizu čimbenika na koje TA ne može utjecati, uključujući ali ne
            ograničavajući se na:
          </div>
          <ul>
            <li>
              stanje i točnost zemljišnih knjiga i katastra u RH za konkretno područje na kojem se nalazi nekretnina;
            </li>
            <li>
              dostupnost i točnost podataka u hrvatskim javnim očevidnicima i arhivima;
            </li>
            <li>
              dostupnost i vjerodostojnost podataka na kojima se temelji zahtjev (uključujući fizičku dostupnost
              izvornika, tamo gdje je potrebno);
            </li>
            <li>
              identitet, dostupnost i suradnju bilo kojeg člana Obiteljskog stabla koji može biti potreban za
              pribavljanje ili potpis određenih ugovora, izjava ili isprava;
            </li>
            <li>
              postojanje, dostupnost i suradnju Suvlasnika;
            </li>
            <li>
              postojanje dosjelosti;
            </li>
            <li>
              sadržaj hrvatskih zakona, propisa te sudsku praksu, koji se mogu promijeniti u bilo kojem trenutku.
            </li>
          </ul>
        </div>
        <p>
          TA ne daje obvezujuće procjene Nekretnina i ne procjenjuje ili obećava tržišnu cijenu istih. TA ne pruža
          financijske ni investicijske savjete niti savjete za upravljanje imovinom.
        </p>
      </div>

    </div>
    <div v-else class="container pt-5 pb-5">
      <div>
        <h3 class="text-center">Disclaimer</h3>
        <p>
          The Service does not constitute legal advice in itself. The Service provides aggregated information collected
          from various publicly available sources which has been processed and collated by our proprietary algorithms,
          copyrighted software and internal know-how.
        </p>
        <p>
          TA is not, and does not purport to be, a registered attorney. Any legal assessments and advice will be
          provided by qualified Croatian attorneys engaged by TA and expressly identified as such in the deliverables.
        </p>
        <p>
          No attorney-client relationship exists between TA and the Registered User. TA will protect the confidentiality
          in accordance with the Privacy Policy described on TA website, however the information a Registered User
          provides to TA is not protected under the laws relating to attorney-client privilege and TA may be compelled
          to disclose information provided to it in certain legal proceedings. Accordingly, a Registered User should not
          provide any information concerning its legal needs which should be protected by attorney-client privilege.
        </p>
        <p>
          TA does not advertise or endorse specific attorneys and does not directly offer or encourage Registered Users
          to engage specific attorneys. Any Registered Users who wish to engage a Croatian attorney shall proceed with
          such engagement at their own incentive and risk, based on their own evaluation and judgement.
        </p>
        <p>
          TA does not guarantee the outcome of any legal proceedings, does not confirm or warrant the authenticity of
          any document submitted by a Register User and does not confirm the identity of any person contained in such
          document.
        </p>
        <div class="mb-5">
          <div>
            TA does not promise any Registered User that its claim or purported claim will be confirmed. The results of
            the assessment, recommendations and proceedings depend on a variety of factors not influenced by TA,
            including but not limited to:
          </div>
          <ul>
            <li>
              the status and accuracy of Land Register and Cadastral Register in Croatia for the particular area where
              the land is located,
            </li>
            <li>
              the availability and accuracy of data in the Croatian public registers and archives,
            </li>
            <li>
              the availability and authenticity of documents evidencing the claim (including the physical availability
              of originals, where applicable),
            </li>
            <li>
              the identity, availability and cooperation of any Family Tree member who may need to deliver or sign
              certain agreements, statements or documents,
            </li>
            <li>
              the existence, availability and cooperation of Co-Owners,
            </li>
            <li>
              the existence of adverse possession,
            </li>
            <li>
              the content of Croatian laws and regulations and case law, which may change from time to time.
            </li>
          </ul>
        </div>
        <p>
          TA does not provide any binding valuation of the Land and does not assess or promise the market price of the
          Land. TA does not provide financial, investment or asset management advice.
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Disclaimer"
};
</script>

<style scoped>
h3 {
  margin-bottom: 30px;
}
.p-cont {
  margin-bottom: 10px;
}

.side-num {
  width: 70px;
}

.side-text {
  width: calc(100% - 70px);
}
</style>
